import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from './components/Banner';
import Skills from "./components/Skills";
import  Projects  from "./components/Projects";
import Contact  from "./components/Contact";
import Footer from "./components/Footer";
import History from './components/History';
import ServicesPricing from './components/ServicesPricing';
import Career from './components/Career';



function App() {
  return (
    <div className="App">
     <NavBar/>
     <Banner />
     <History/>
     <Skills />
     <ServicesPricing />
     <Projects />
     <Career />
     <Contact />
     <Footer />
    </div>
  );
}

export default App;

