import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import  ProjectCard  from "./ProjectCard";
import taxiyetu from "../assets/img/taxi.png";
import projImg2 from "../assets/img/famers.png";
import projImg3 from "../assets/img/market.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

function Projects () {

  const projects = [
    {
      title: "INDMARKET E-Commerce app",
      description: "Busy in Development",
      imgUrl: projImg3,
    },
    {
      title: "Taxi Yetu -Taxi-App",
      description: "Developed",
      imgUrl: taxiyetu,
    },
    {
      title: "Farmers Connect - Management",
      description: "Design, Development & Deployment",
      imgUrl: projImg2,
    },

    // {
    //   title: "Mobile Banking Solutions",
    //   description: "Design, Development & Deployment",
    //   imgUrl: taxiyetu,
    // },
    // {
    //   title: "Agri-Tech Innovations",
    //   description: "Design, Development & Deploymentt",
    //   imgUrl: projImg2,
    // },
    // {
    //   title: "In The Market E-commerce",
    //   description: "...Coming soon...",
    //   imgUrl: projImg3,
    // },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p className="introduction">Teknikecho’s projects focus on delivering comprehensive technology solutions tailored to client needs. 
                  These projects typically involve developing custom software, web applications, and data management systems. </p>
                  <p>The process encompasses understanding client requirements, designing and building the solution, rigorous testing, 
                  and final deployment. </p> <p>Post-launch, Teknikecho provides ongoing support and maintenance to ensure optimal performance 
                  and address any emerging issues, ensuring the solutions are effective and aligned with client goals.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Showcases</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Steps we take</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Our educational focus</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>Here’s a description in three names for sections of a project at Teknikecho: </p>

                      <p>1.⁠ ⁠Innovation Hub:</p>
                       <p>This section focuses on exploring and integrating new technologies and methodologies. It’s where the team brainstorms and develops cutting-edge solutions, prototypes, and concepts to push the boundaries of what's possible.</p>  

                         <p>2.⁠ ⁠Development Zone:</p>
                           <p>Here, the core development work takes place. This section is dedicated to coding, testing, and refining the software or hardware. It’s where the ideas from the Innovation Hub are turned into functional products and systems.</p>

                       <p>3.⁠ ⁠Client Solutions:</p>
                           <p>This section is centered around customizing and delivering solutions to clients. It involves understanding client needs, tailoring the technology to meet those needs, and ensuring that the final product or service aligns with client expectations and requirements.</p>

                          <p>These sections collectively contribute to the successful execution of projects at Teknikecho.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Teknikecho’s education projects focus on leveraging technology to enhance learning experiences 
                        and accessibility.</p> <p> They develop innovative digital tools and platforms to support educational institutions 
                        and learners. </p> <p>This includes creating interactive e-learning modules, virtual classrooms, and adaptive 
                        learning systems designed to cater to diverse educational needs.</p> <p> By integrating cutting-edge technology 
                        with pedagogical expertise, Teknikecho aims to improve educational outcomes and bridge gaps in access 
                        to quality education.</p>

                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}

export default Projects;