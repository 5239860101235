import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

function Newsletter ({ status, message, onValidated })  {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    })
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
      <Col lg={12}> 
          <div className="newsletter-bx wow slideInUp ">
            <Row>
            <h4>Ready to take your project to the next level? Connect with the Teknikecho team today and let’s turn your ideas into reality. We're here to provide the expertise and solutions you need!</h4>

              <Col lg={6} md={6} xl={5} className="n">
                {/* <h3 className="news">Subscribe to our Newsletter<br></br> & Never miss latest updates</h3> */}
                {status === 'sending' && <Alert>Sending...</Alert>}
                {status === 'error' && <Alert variant="danger">{message}</Alert>}
                {status === 'success' && <Alert variant="success">{message}</Alert>}
              </Col>
              {/* <Col md={6} xl={7}> */}
                {/* <form onSubmit={handleSubmit}>
                  <div className="new-email-bx">
                    <input value={email} type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                    <button type="submit">Submit</button>
                  </div>
                </form> */}
              {/* </Col> */}
            </Row>
          </div>
      </Col>
  )
}

export default Newsletter;