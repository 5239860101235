import React from "react";
import headerImg2 from "../assets/img/it.jpg";
import headerImg1 from "../assets/img/iiti.jpg";
import headerImg3 from "../assets/img/itii.jpg";

import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Container, Row, Col } from "react-bootstrap";

const ServicesPricing = () => {
  return (
    <section className="pricing" id="pricing">
    <Container>
      <Row className="aligh-items-center">
      <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
             
                <div className="">
   
                  <img src={headerImg3} alt="Header Img"/>
                  <img src={headerImg1} alt="Header Img"/>
                  <img src={headerImg2} alt="Header Img"/>
                </div>
            </TrackVisibility>
          </Col>
        <Col xs={12} md={6} xl={6}>
          <TrackVisibility>
            
            <div className="skill-bx wow zoomIn">
      <h1> Services & Pricing</h1>
      <p>
        At Teknikecho, we offer tailored tech solutions that meet your business
        needs. With a strong focus on quality, efficiency, and reliability, we
        ensure that every project delivers results. As a token of our
        appreciation, we offer <span>50% off</span> on your{" "}
        <span>first project</span> with us.
      </p>

      <h2>Our Services</h2>

      <h3> Web Development</h3>
      <p>
        <span>Service Overview:</span> We provide full-stack web development
        services using modern frameworks like React, Node.js, and PHP. Whether
        it's an e-commerce platform, blog, or corporate website, we deliver
        seamless user experiences.
      </p>
      <p>
        <span>Cost:</span> Starting at $1,500 per project.
      </p>
      <p>
        <span>Time Frame:</span> 4-8 weeks (depending on project
        complexity).
      </p>
      <p>
        <span>What’s Included:</span>
      </p>
      <ul>
        <li>Custom UI/UX Design</li>
        <li>Frontend & Backend Development</li>
        <li>SEO Optimization</li>
        <li>3-Month Post-Launch Support</li>
      </ul>

      <h3> Mobile Application Development</h3>
      <p>
        <span>Service Overview:</span> We create intuitive and scalable
        mobile apps for both iOS and Android using React Native and Flutter. Our
        apps are designed to enhance user engagement and streamline business
        processes.
      </p>
      <p>
        <span>Cost:</span> Starting at $2,500 per app.
      </p>
      <p>
        <span>Time Frame:</span> 6-12 weeks.
      </p>
      <p>
        <span>What’s Included:</span>
      </p>
      <ul>
        <li>UI/UX Design for Mobile</li>
        <li>Cross-Platform App Development</li>
        <li>API Integration</li>
        <li>App Store Deployment</li>
      </ul>

      <h3> Data Management & Analysis</h3>
      <p>
        <span>Service Overview:</span> We offer comprehensive data solutions
        that include database design, data integration, and advanced analytics
        to help your business make data-driven decisions.
      </p>
      <p>
        <span>Cost:</span> Starting at $1,000 per project.
      </p>
      <p>
        <span>Time Frame:</span> 3-6 weeks.
      </p>
      <p>
        <span>What’s Included:</span>
      </p>
      <ul>
        <li>Database Design & Optimization</li>
        <li>Custom Data Dashboards</li>
        <li>Data Cleaning & Reporting Tools</li>
      </ul>

      <h3> Cloud Solutions & Server Management</h3>
      <p>
        <span>Service Overview:</span> We offer cloud architecture and
        server management solutions that ensure your applications and databases
        run smoothly, securely, and efficiently.
      </p>
      <p>
        <span>Cost:</span> Starting at $800 per month.
      </p>
      <p>
        <span>Time Frame:</span> Ongoing (with a minimum of 6-month
        commitment).
      </p>
      <p>
        <span>What’s Included:</span>
      </p>
      <ul>
        <li>Cloud Migration Services</li>
        <li>Server Monitoring & Maintenance</li>
        <li>Backup & Recovery Solutions</li>
      </ul>

      <h3> IT Consulting & Strategy</h3>
      <p>
        <span>Service Overview:</span> Get expert advice on optimizing your
        technology infrastructure. We help businesses streamline their IT
        operations and choose the right tools for success.
      </p>
      <p>
        <span>Cost:</span> Starting at $100 per hour.
      </p>
      <p>
        <span>Time Frame:</span> Based on consultation needs.
      </p>
      <p>
        <span>What’s Included:</span>
      </p>
      <ul>
        <li>Comprehensive IT Strategy Development</li>
        <li>Technology Assessment</li>
        <li>System Integration Recommendations</li>
      </ul>

      <h2>Pricing Structure</h2>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "12px" }}>Service</th>
            <th style={{ border: "1px solid #ddd", padding: "12px" }}>Starting Price</th>
            <th style={{ border: "1px solid #ddd", padding: "12px" }}>Time Frame</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>Web Development</td>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>$1,500+</td>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>4-8 weeks</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>Mobile App Development</td>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>$2,500+</td>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>6-12 weeks</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>Data Management & Analytics</td>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>$1,000+</td>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>3-6 weeks</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>Cloud Solutions & Server Management</td>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>$800/month</td>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>Ongoing (min 6 months)</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>IT Consulting & Strategy</td>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>$100/hour</td>
            <td style={{ border: "1px solid #ddd", padding: "12px" }}>Based on consultation needs</td>
          </tr>
        </tbody>
      </table>

      <h2>50% Discount Offer</h2>
      <p>
        For first-time clients, we’re excited to offer <span>50% off</span>{" "}
        on your <span>first project</span> with us. This offer applies to
        any of the services listed above, giving you the opportunity to
        experience the Teknikecho difference at half the price. Don’t miss out
        on this limited-time deal!
      </p>

      <h2>Why Choose Teknikecho?</h2>
      <ul>
        <li>
          <span>Proven Expertise:</span> Years of experience delivering
          projects across various industries.
        </li>
        <li>
          <span>Custom Solutions:</span> Each project is tailored to fit
          your specific business needs.
        </li>
        <li>
          <span>Cutting-Edge Technology:</span> We use the latest tools and
          frameworks to build scalable and secure solutions.
        </li>
        <li>
          <span>Dedicated Support:</span> Our team offers ongoing support to
          ensure your projects succeed even after launch.
        </li>
      </ul>

      

      </div>
            </TrackVisibility>
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default ServicesPricing;
