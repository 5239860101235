
import { Container, Row, Col } from "react-bootstrap";
import headerImg1 from "../assets/img/rsa4.png";
import headerImg2 from "../assets/img/rdc1.png";
import headerImg3 from "../assets/img/g.png";
import headerImg4 from "../assets/img/rsa1.png";
import headerImg5 from "../assets/img/rsa2.png";
import headerImg6 from "../assets/img/rdc5.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

function History() {
 


  return (
    <section className="history" id="history">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={6}>
            <TrackVisibility>
              
              <div className="skill-bx wow zoomIn">
                    <h1>Our Journey!</h1> 
                    <h3>Early education in software development</h3>
                    <p>Raice Reagan MI attended CodeSpace in 2018, earning a certificate in PHP and JavaScript, which launched his career at OneDayOnly, an e-commerce company. He continued enhancing his skills through MongoDB University, Udemy Academy, and online tutorials. His career progressed through roles at Fireflex cc, Book Dash, Rain South Africa, BMI, and eventually Teknikecho.</p>
                    <h3>Where Teknikecho name came from?</h3>
                    <p>Teknikecho comes from a combination of the words 'technology ni kesho,' which means 'technology is tomorrow' in Swahili. Our slogan, '*Anza Leo*,' translates to 'start today,' encouraging everyone, especially Africans, to recognize that the future is driven by technology. By starting today, you ensure that you won't be left behind as the world moves forward with innovation.</p>
                    <h3>Teknikecho Foundation and Early Challenges</h3>
                    <p>Teknikecho was founded on 11 November 2021 by Raice Reagan MI, Fonder and CEO, who hails from the DRCongo and based in South Africa. Driven by a vision to change the world through technology and grounded in a deep trust in God, Raice collaborated early on with other developers, engineers, family and friends. Some joined the company, leaving their mark on its early foundations. Together, we shared a goal: to advance technology education and create jobs across Africa.
                    </p> 
                    <p>In its early days, Teknikecho worked tirelessly to lay a strong foundation. The company was born during a time of global uncertainty, with the COVID-19 pandemic and economic crises presenting unprecedented challenges. Despite these difficulties, we remained focused on our mission. we believed technology could be a powerful force for good, addressing pressing issues and paving the way for a better future.</p> 

                    <h3>Building a Rock-Solid Future</h3> 
                    <p>Teknikecho's journey has been marked by resilience. The challenges of the pandemic and economic downturn tested the team's resolve, but we remained steadfast. We focused on its vision of creating technology solutions that could help individuals and businesses navigate the new realities of a post-pandemic world. This included developing digital tools for remote work, education, and small business support—resources that became essential as people adapted to the changes brought about by the global crisis.</p> 

                    <p>Through these trials, Teknikecho developed what they call "rock patterns"—fundamental principles that guide the company's future. These patterns are built on values of resilience, ethical innovation, and a commitment to community empowerment. They form the foundation of Teknikecho's approach to technology and its vision for the future.</p>

                    <h3>Education Plan</h3> 
                    <p>The company recognized that to make a lasting impact, we needed to invest in people. Teknikecho plans to launch educational programs aimed at developing the next generation of software developers and tech leaders. These initiatives are designed to equip young people with the skills needed to succeed in the global tech industry, while also fostering leadership and ethical responsibility. </p> 

                    <h3>Creating Jobs and Developing Global Leaders</h3> 
                    <p>Teknikecho's educational and mentorship programs will led to job creation both within the company and beyond. The impact of these efforts has extended far beyond the borders of the DRC and South Africa, as graduates of Teknikecho’s programs have gone on to become influential software developers and tech leaders on the global stage. The company's commitment to creating jobs and developing leaders has become a key part of its identity, with its influence continuing to grow. </p> 

                    <h3>Vision for the Future</h3> 
                    <p>Today, Teknikecho remains committed to the vision it set out to achieve. The company’s journey through the challenges of the pandemic and economic crises has only strengthened its resolve. Teknikecho's focus on education, job creation, and leadership development will continue to be at the heart of our mission as it seeks to empower communities in the DRC, South Africa, and beyond.</p> 

                    <p>Through its efforts, Teknikecho is not just building a successful company; it is shaping the future of technology in a way that is rooted in strong values and dedicated to solving real-world problems. Teknikecho is determined to continue its work until its objectives are fully realized, confident in the knowledge that we are building something with a lasting impact on the world.</p>

              </div>
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <TrackVisibility>
             
                <div className="">
                  {/* <img src={headerImg1} alt="Header Img"/> */}
                  {/* <img src={headerImg2} alt="Header Img"/> */}
                  <img src={headerImg1} alt="Header Img"/>
                  <img src={headerImg2} alt="Header Img"/>
                  <img src={headerImg3} alt="Header Img"/>
                  <img src={headerImg4} alt="Header Img"/>
                  <img src={headerImg5} alt="Header Img"/>
                  {/* {/* <img src={headerImg4} alt="Header Img"/> */}
                  <img src={headerImg6} alt="Header Img"/> 
                </div>
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default History;




