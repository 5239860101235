import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import TrackVisibility from 'react-on-screen';
import headerImg4 from '../assets/img/career.jpg'



const Career = () => {
  return (
    <section className="career" id='career'>
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={4}>
            <TrackVisibility>
              <div >
                <header className="career-header">
                  <h1>Join Our Team</h1>
                  <p className="introduction">At Teknikecho, we believe that our people are our greatest asset. We are always on the lookout for talented individuals who are passionate about technology, innovation, and making a difference.</p>
                </header>
                <section className="values">
                  <h2>Our Core Values</h2>
                  <ul>
                    <li><span>Innovation:</span> We thrive on creativity and are dedicated to pushing the boundaries of technology to deliver cutting-edge solutions.</li>
                    <li><span>Integrity:</span> We operate with honesty and transparency in all our interactions, ensuring that we build trust with our clients and colleagues.</li>
                    <li><span>Collaboration:</span> We value teamwork and believe that the best results come from working together and supporting one another.</li>
                    <li><span>Excellence:</span> We strive for excellence in everything we do, continually seeking to improve and exceed expectations.</li>
                    <li><span>Growth:</span> We are committed to personal and professional development, encouraging continuous learning and growth for all our team members.</li>
                  </ul>
                </section>
                <section className="profiles">
                  <h2>What We're Looking For</h2>
                  <p>We are excited to welcome new team members who share our values and possess the following qualities:</p>
                  <ul>
                    <li><span>Technical Expertise:</span> Proficiency in relevant technologies and a strong problem-solving mindset.</li>
                    <li><span>Passion:</span> A genuine enthusiasm for technology and a drive to stay ahead of industry trends.</li>
                    <li><span>Adaptability:</span> The ability to thrive in a fast-paced environment and adapt to new challenges.</li>
                    <li><span>Team Spirit:</span> A collaborative attitude and the ability to work well with diverse teams.</li>
                    <li><span>Innovative Thinking:</span> A creative approach to tackling problems and developing solutions.</li>
                  </ul>
                </section>
                <section className="positions">
                  <h2>Upcoming Opportunities</h2>
                  <p>We will soon be posting available positions on our careers page. Stay tuned for exciting opportunities to join our dynamic team!</p>
                </section>
                <footer className="career-footer">
                  <p>If you are passionate about making a difference and want to be a part of our journey, we encourage you to check back regularly for updates on available positions.</p>
                </footer>
              </div>
            </TrackVisibility>
          </Col>
          <Col size={12} md={6} xl={5}>
            <TrackVisibility>
              {/* {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              } */}
              <img src={headerImg4} alt="Header Img"/>

            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      </section>
  );
};

export default Career;
